@import url('https://fonts.googleapis.com/css2?family=Electrolize&family=Tenor+Sans&display=swap');

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --background-url: none;
  --background-size: 6%;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

#__next {
  /* max-width: 100%; */
  width: 100%;
  max-width: var(--max-width);
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  min-width: 600px;
  max-width: 100vw;
  /* overflow-x: hidden; */
}

body {
  color: rgb(var(--foreground-rgb));
  background: #bbbbbb;
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

body::before {
  content: ' ';
  display: block;
  background-image: var(--background-url);
  background-size: var(--background-size, 6%);
  filter: grayscale(0.8) contrast(0.5) brightness(0.6) blur(0.4px);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  transform: rotateZ(-10deg) scale(2);
}

a {
  color: inherit;
  text-decoration: none;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

.ratingBadge {
  display: inline-block;
  background: orange;
  padding: 3px 5px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
  font-family: cursive;
  vertical-align: bottom;
}

.bunny {
  display: inline-block;
  width: 36px;
  height: 30px;
  background: url('/bunnysheet5.png');
  background-repeat: no-repeat;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

.bunny.running {
  background-position: -22px -216px;
  animation: bunnyRunning 0.4s infinite;
  animation-timing-function: step-end !important;
}

@keyframes bunnyRunning {
  0% {
    background-position: -22px -216px;
  }
  25% {
    background-position: -53px -216px;
  }
  50% {
    background-position: -89px -216px;
  }
  75% {
    background-position: -131px -216px;
  }
}

.bunny.idle {
  background-position: -136px -16px;
  animation: bunnyIdle 0.8s infinite;
  animation-timing-function: step-end !important;
  width: 25px;
  height: 27px;
}

@keyframes bunnyIdle {
  0% {
    background-position: -136px -16px;
  }
  50% {
    background-position: -136px -49px;
  }
}

.link {
  color: #0288d1;
  cursor: pointer;
}

.link:hover, .link:active {
  text-decoration: underline;
}
